import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const Liabilities = () => {
  const { liabilities, setLiabilities } = useFinancialPlan();

  const handleInputChange = (e) => {
    setLiabilities((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Liabilities</h3>
      <div className={styles.row}>
        <div>
          <label>Outstanding Debts</label>
          <input
            type="text"
            name="outstandingDebts"
            value={liabilities.outstandingDebts}
            placeholder="Enter total outstanding debts (e.g., mortgage, loans)"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Monthly Debt Payments</label>
          <input
            type="text"
            name="monthlyDebtPayments"
            value={liabilities.monthlyDebtPayments}
            placeholder="Enter monthly debt payments"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Liabilities;
