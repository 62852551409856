import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFinancialPlan } from "./FinancialPlanContext";
import styles from "./UserInformation.module.css";
import PersonalInformation from "./sections/PersonalInformation";
import IncomeDetails from "./sections/IncomeDetails";
import ExpenseOverview from "./sections/ExpenseOverview";
import Assets from "./sections/Assets";
import Liabilities from "./sections/Liabilities";
import FinancialGoals from "./sections/FinancialGoals";
import RiskTolerance from "./sections/RiskTolerance";
import TaxSituation from "./sections/TaxSituation";
import InsuranceCoverage from "./sections/InsuranceCoverage";
import EstatePlanning from "./sections/EstatePlanning";
import RetirementPlans from "./sections/RetirementPlans";
import OtherConsiderations from "./sections/OtherConsiderations";
import MajorExpense from "./sections/MajorExpense";
import Uploads from "./sections/Uploads";
import { useAuthState } from "../../UserAuth";

const FormPage = () => {
  const user = useAuthState();
  const email = user?.email;
  const {
    setPersonalInformation,
    personalInformation,
    setIncomeDetails,
    incomeDetails,
    setExpenseOverview,
    expenseOverview,
    setAssets,
    assets,
    setLiabilities,
    liabilities,
    setFinancialGoals,
    financialGoals,
    setRiskTolerance,
    riskTolerance,
    setTaxSituation,
    taxSituation,
    setInsuranceCoverage,
    insuranceCoverage,
    setEstatePlanning,
    estatePlanning,
    setRetirementPlans,
    retirementPlans,
    setOtherConsiderations,
    otherConsiderations,
    setMajorExpense,
    majorExpense,
    setUploads,
    uploads,
  } = useFinancialPlan();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (email) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/financial-plan/${email}`
          );
          const data = response.data;

          setPersonalInformation(data.personalInformation);
          setIncomeDetails(data.incomeDetails);
          setExpenseOverview(data.expenseOverview);
          setAssets(data.assets);
          setLiabilities(data.liabilities);
          setFinancialGoals(data.financialGoals);
          setRiskTolerance(data.riskTolerance);
          setTaxSituation(data.taxSituation);
          setInsuranceCoverage(data.insuranceCoverage);
          setEstatePlanning(data.estatePlanning);
          setRetirementPlans(data.retirementPlans);
          setOtherConsiderations(data.otherConsiderations);
          setMajorExpense(data.majorExpense);
          setUploads(data.uploads);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [email]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const formData = {
      email: email || "",
      personalInformation: personalInformation || {},
      incomeDetails: incomeDetails || {},
      expenseOverview: expenseOverview || {},
      assets: assets || {},
      liabilities: liabilities || {},
      financialGoals: financialGoals || {},
      riskTolerance: riskTolerance || { riskLevel: 3 },
      taxSituation: taxSituation || {},
      insuranceCoverage: insuranceCoverage || {},
      estatePlanning: estatePlanning || {},
      retirementPlans: retirementPlans || {},
      otherConsiderations: otherConsiderations || {},
      majorExpense: majorExpense || "",
      uploads: uploads || {},
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/financial-plan`,
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      );
      
      console.log(formData)
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <PersonalInformation />
        <IncomeDetails />
        <ExpenseOverview />
        <Assets />
        <Liabilities />
        <FinancialGoals />
        <RiskTolerance />
        <TaxSituation />
        <InsuranceCoverage />
        <EstatePlanning />
        <RetirementPlans />
        <OtherConsiderations />
        <MajorExpense />
        <Uploads />

        <div className={styles.submitButton}>
          <button onClick={handleSubmit}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormPage;
