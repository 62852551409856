import React from 'react'
import styles from '../personalDetails/personalDetails.module.css'
import InputControl from '../InputControl/InputControl';
import { useExpertDetails } from '../../ExpertProfileContext';

const ExperienceDetails = () => {
    const { experienceDetails, setExperienceDetails } = useExpertDetails();
    return (
        <div className={styles.userDetails}>
            <div className={styles.headingContainer}>
                <h6>Experience Details</h6>
            </div>
            <div className={styles.detail}>
                <InputControl
                    label="Description"
                    type="text"
                    placeholder="Enter description"
                    value={experienceDetails.description}
                    onChange={(e) => setExperienceDetails({ ...experienceDetails, description: e.target.value })}
                />
                <InputControl
                    label="Linkedin Id"
                    type="text"
                    placeholder="linkedin id"
                    value={experienceDetails.linkedin}
                    onChange={(e) => setExperienceDetails({ ...experienceDetails, linkedin: e.target.value })}
                />
            </div>
        </div>
    )
}

export default ExperienceDetails
