import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const RetirementPlans = () => {
  const { retirementPlans, setRetirementPlans } = useFinancialPlan();

  const handleInputChange = (e) => {
    setRetirementPlans((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Retirement Plans</h3>
      <div className={styles.row}>
        <div>
          <label>Expected Retirement Age</label>
          <input
            type="number"
            name="expectedRetirementAge"
            value={retirementPlans.expectedRetirementAge}
            placeholder="Enter expected retirement age"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Lifestyle Goals</label>
          <input
            type="text"
            name="lifestyleGoals"
            value={retirementPlans.lifestyleGoals}
            placeholder="Enter lifestyle goals"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Current Savings Rate</label>
          <input
            type="text"
            name="currentSavingsRate"
            value={retirementPlans.currentSavingsRate}
            placeholder="Enter current savings rate"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Employer Contributions</label>
          <input
            type="text"
            name="employerContributions"
            value={retirementPlans.employerContributions}
            placeholder="Enter employer contributions"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RetirementPlans;
