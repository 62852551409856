import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const ExpenseOverview = () => {
  const { expenseOverview, setExpenseOverview } = useFinancialPlan();

  const handleInputChange = (e) => {
    setExpenseOverview((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Expense Overview</h3>
      <div className={styles.row}>
        <div>
          <label>Monthly Living Expenses</label>
          <input
            type="text"
            name="monthlyLivingExpenses"
            value={expenseOverview.monthlyLivingExpenses}
            placeholder="Enter monthly living expenses"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Discretionary Spending</label>
          <input
            type="text"
            name="discretionarySpending"
            value={expenseOverview.discretionarySpending}
            placeholder="Enter discretionary spending"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Annual or Irregular Expenses</label>
          <input
            type="text"
            name="annualIrregularExpenses"
            value={expenseOverview.annualIrregularExpenses}
            placeholder="Enter annual or irregular expenses"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Mortgage / Loans</label>
          <input
            type="text"
            name="mortgageLoans"
            value={expenseOverview.mortgageLoans}
            placeholder="Enter mortgage or loans"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ExpenseOverview;
