import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const IncomeDetails = () => {
  const { incomeDetails, setIncomeDetails } = useFinancialPlan();

  const handleInputChange = (e) => {
    setIncomeDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Income Details</h3>
      <div className={styles.row}>
        <div>
          <label>Salary</label>
          <input
            type="text"
            name="salary"
            value={incomeDetails.salary}
            placeholder="Enter current salary"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Bonuses</label>
          <input
            type="text"
            name="bonuses"
            value={incomeDetails.bonuses}
            placeholder="Enter bonuses"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Additional Income Sources</label>
          <input
            type="text"
            name="additionalIncomeSources"
            value={incomeDetails.additionalIncomeSources}
            placeholder="Investments, rental income, side businesses"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Employment Status</label>
          <input
            type="text"
            name="employmentStatus"
            value={incomeDetails.employmentStatus}
            placeholder="Enter employment status"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Total Annual Income (before taxes)</label>
          <input
            type="text"
            name="annualIncome"
            value={incomeDetails.annualIncome}
            placeholder="Enter total annual income"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Currency</label>
          <select
            name="currency"
            value={incomeDetails.currency}
            onChange={handleInputChange}
          >
            <option value="INR">INR</option>
            <option value="USD">$</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Total Monthly Expenses</label>
          <input
            type="text"
            name="monthlyExpenses"
            value={incomeDetails.monthlyExpenses}
            placeholder="Enter total monthly expenses"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Current Total Savings</label>
          <input
            type="text"
            name="totalSavings"
            value={incomeDetails.totalSavings}
            placeholder="Enter total savings across all accounts"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Total Outstanding Debts</label>
          <input
            type="text"
            name="totalDebts"
            value={incomeDetails.totalDebts}
            placeholder="Enter total outstanding debts (e.g., mortgages, loans)"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default IncomeDetails;
