import React, {useEffect, useRef} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Subtitle, Title } from "./InfoElements";
import { ButtonBasic } from "../ButtonElements";
import { motion, useInView,useAnimation } from "framer-motion"

export const InfoSection = ({ id, title, subtitle, text, image, btnText }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true});
  const mainControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView])
  
  return (
    <>
      <InfoContainer id={id}>
        <InfoWrapper>
          <motion.div ref={ref} className="row expand-row gx-5 border-4 "
          variants={{hidden: {opacity: 0, y:100},visible: {opacity: 1, y:0}}}
          initial = "hidden"
          animate = {mainControls}
          transition={{duration:0.5, delay:0.25}}
          >
            <div className="col-lg-6 col-sm-12 my-auto">
              <Subtitle>{subtitle}</Subtitle>
              <Title className="text-black mb-5">{title}</Title>
              <p className="text-black mb-4">{text}</p>
              <ButtonBasic to="" className="mx-auto">
                {btnText}
              </ButtonBasic>
            </div>
            <div className="col-lg-6 col-sm-12 img-wrap">
              <img src={image} alt="" className="fit-img"></img>
            </div>
          </motion.div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
