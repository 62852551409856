import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const OtherConsiderations = () => {
  const { otherConsiderations, setOtherConsiderations } = useFinancialPlan();

  const handleInputChange = (e) => {
    setOtherConsiderations((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Other Considerations</h3>
      <div className={styles.row}>
        <div>
          <label>Significant Life Changes</label>
          <input
            type="text"
            name="significantLifeChanges"
            value={otherConsiderations.significantLifeChanges}
            placeholder="Enter significant life changes"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Health Issues</label>
          <input
            type="text"
            name="healthIssues"
            value={otherConsiderations.healthIssues}
            placeholder="Enter any health issues"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Review Frequency</label>
          <select
            name="reviewFrequency"
            value={otherConsiderations.reviewFrequency}
            onChange={handleInputChange}
          >
            <option value="Annual">Annual</option>
            <option value="Quarterly">Quarterly</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default OtherConsiderations;
