import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import { auth } from "../../firebase";
import {Nav,NavbarContainer,NavLogo,MobileIcon,NavMenu,NavItem,NavLinks,NavLink,NavBtn,NavBtnLink, NavButtonContainer, SearchButton} from "./NavbarElements";
import "./../../App.css";
import  {useAuthState}  from "../../UserAuth";
import { FaSearch } from 'react-icons/fa';
import { useUser } from "../../UserContext";


export const Navbar = ({ toggle, backgroundColor }) => {
  const { userType, setUserType } = useUser();
  const user = useAuthState();
  const [scrollNav, setScrollNav] = useState(false);
  const navigate = useNavigate();

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
      window.addEventListener('scroll', changeNav);      
  }, []);

  const handleSignOut = async (e) => {
    e.preventDefault();
    setUserType(null);
    // After successfully setting userType, store it in local storage
    localStorage.setItem('userType', null);
    await auth
      .signOut()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log("Sign out error:", error);
      });
      localStorage.removeItem('userId');
      localStorage.removeItem('expertId');
  };

  const handleSearchClick = () => {
    navigate("/experts");
  };

  return (
    <>
      <Nav scrollNav={scrollNav} style={{ background: backgroundColor }}>
        <NavbarContainer>
          <NavLogo to="/">
            <h1 className="logo-text">NriTax</h1>
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLink to="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about-us">About us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/services">Services</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/file-tips">File Tips</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/contact">Contact</NavLink>
            </NavItem>
          </NavMenu>

          <NavButtonContainer>
            {user ? (
              localStorage.getItem('userType') === "user" ? (
                <>
                  <SearchButton onClick={handleSearchClick}>
                    <FaSearch />
                  </SearchButton>

                  <NavBtn>
                    <NavBtnLink to='/user-profile' >My account</NavBtnLink>
                  </NavBtn>

                  <NavBtn>
                    <NavBtnLink to='/user-info'>User Information</NavBtnLink>
                  </NavBtn>

                  <NavBtn>
                    <NavBtnLink onClick={handleSignOut}>Log out</NavBtnLink>
                  </NavBtn>
                </>
              ) : localStorage.getItem('userType') === "expert" ? (
                <>
                  <NavBtn>
                    <NavBtnLink to="/allocated-users">Customers</NavBtnLink>
                  </NavBtn>
                  <NavBtn>
                    <NavBtnLink to="/expert-profile">Profile</NavBtnLink>
                  </NavBtn>
                  <NavBtn>
                    <NavBtnLink onClick={handleSignOut}>Log out</NavBtnLink>
                  </NavBtn>
                </>
              ) : (
                <>
                  <SearchButton onClick={handleSearchClick}>
                    <FaSearch />
                  </SearchButton>
                  <NavBtn>
                    <NavBtnLink to="/signin">Sign In</NavBtnLink>
                  </NavBtn>
                  <NavBtn>
                    <NavBtnLink to="/select-role">Sign Up</NavBtnLink>
                  </NavBtn>
              </>
              )
            ) : (
              <>
                <SearchButton onClick={handleSearchClick}>
                  <FaSearch />
                </SearchButton>
                <NavBtn>
                  <NavBtnLink to="/signin">Sign In</NavBtnLink>
                </NavBtn>
                <NavBtn>
                  <NavBtnLink to="/select-role">Sign Up</NavBtnLink>
                </NavBtn>
              </>
            )}
          </NavButtonContainer>
        </NavbarContainer>
      </Nav>
    </>
  );
};
