import React from "react";
import "./App.css";
import { ExpertDetailsProvider } from "./ExpertProfileContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./pages/auth/signUp";
import Login from "./pages/auth/logIn";
import ResetPasswordPage from "./pages/auth/forgotPassword";
import Customers from "./components/Customers/Customers";
import SignUpOptions from "./components/SignUpOptions/SignUpOptions";
import Home from "./pages/index";
import { useAuthState } from "./UserAuth";
import { UserProvider } from "./UserContext";
import { Helmet } from "react-helmet";
import SearchResult from "./components/GoogleSearchResult/SearchResult";
import FileTips from "./pages/FileTips/FileTips";
import AboutUs from "./pages/About Us/AboutUs";
import Contact from "./pages/Contact/Contact";
import ServicesPage from "./pages/Services/ServicesPage";
import Experts from "./pages/Experts/Experts";
import UserProfile from "./pages/User Profile/UserProfile";
import ExpertProfile from "./pages/Expert Profile/ExpertProfile";
import UserInformation from "./pages/UserInformation/UserInformation";
import { FinancialPlanProvider } from "./pages/UserInformation/FinancialPlanContext";

function App() {
  const user = useAuthState();

  return (
    <UserProvider>
      <BrowserRouter>
        <Helmet>
          <title>
            NRI Tax Solutions - Expert Guidance for Non-Resident Indian Taxation
          </title>
          <meta
            name="description"
            content="Empower NRI tax solutions with expert guidance - Connect, consult, and optimize your tax matters seamlessly on our platform."
          />
          <meta
            name="keywords"
            content="NRI, Taxation, Expert, Consultation, Solutions, Overseas, Financial, Planning, International, Guidance"
          />
        </Helmet>

        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/file-tips" element={<FileTips />} />
          <Route path="/experts" element={<Experts />} />
          <Route path="/select-role" element={<SignUpOptions />} />
          <Route path="/search/:searchTerm" element={<SearchResult />} />
          <Route path="/forgot_password" element={<ResetPasswordPage />} />

          <Route
            path="/allocated-users"
            element={user ? <Customers /> : <Login />}
          />
          <Route
            path="/expert-profile"
            element={
              user ? (
                <ExpertDetailsProvider>
                  {" "}
                  <ExpertProfile />{" "}
                </ExpertDetailsProvider>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/user-profile"
            element={user ? <UserProfile /> : <Login />}
          />

          <Route
            path="/user-info"
            element={
              user ? (
                <FinancialPlanProvider>
                  <UserInformation />
                </FinancialPlanProvider>
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
