import React from 'react'
import styles from '../personalDetails/personalDetails.module.css'
import InputControl from '../InputControl/InputControl';
import { useExpertDetails } from '../../ExpertProfileContext';

const EducationDetails = () => {
    const { educationDetails, setEducationDetails } = useExpertDetails();
    return (
        <div className={styles.userDetails}>
            <div className={styles.headingContainer}>
                <h6>Educational Details</h6>
            </div>
            <div className={styles.detail}>
                <div className={styles.row}>
                    <InputControl
                        label="Degree"
                        type="text"
                        placeholder="eg. B.Tech"
                        value={educationDetails.degree}
                        onChange={(e) => setEducationDetails({ ...educationDetails, degree: e.target.value })}
                    />
                    <InputControl
                        label="University"
                        type="text"
                        placeholder="Enter university"
                        value={educationDetails.university}
                        onChange={(e) => setEducationDetails({ ...educationDetails, university: e.target.value })}
                    />
                </div>
                <div className={styles.row}>
                    <InputControl
                        label="Field of study"
                        type="text"
                        placeholder="Enter field of study"
                        value={educationDetails.fieldOfStudy}
                        onChange={(e) => setEducationDetails({ ...educationDetails, fieldOfStudy: e.target.value })}
                    />
                    <InputControl
                        label="Graduation year"
                        type="number"
                        placeholder="Enter graduation year"
                        value={educationDetails.graduationYear}
                        onChange={(e) => setEducationDetails({ ...educationDetails, graduationYear: e.target.value })}
                    />
                </div>
            </div>
        </div>
    )
}

export default EducationDetails
