import React, {useEffect, useRef} from "react";
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from "./ServicesElements";
import Icon1 from "../../images/space.svg"
import Icon2 from "../../images/online.svg"
import Icon3 from "../../images/real-time.svg"
import { motion, useInView,useAnimation } from "framer-motion"

const Services = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true});
  const mainControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView])

  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <motion.div ref={ref}
        variants={{hidden: {opacity: 0, x:-100},visible: {opacity: 1, x:0}}}
        initial = "hidden"
        animate = {mainControls}
        transition={{duration:0.5, delay:0.25}}
        >
          <ServicesCard>
            <ServicesIcon src={Icon1} />
            <ServicesH2>File on the Go</ServicesH2>
            <ServicesP>
              Filling your taxes made easy with just one click 
            </ServicesP>
          </ServicesCard>
        </motion.div>
        <motion.div 
        variants={{hidden: {opacity: 0, y:-100},visible: {opacity: 1, y:0}}}
        initial = "hidden"
        animate = {mainControls}
        transition={{duration:0.5, delay:0.25}}
        >
          <ServicesCard>
            <ServicesIcon src={Icon2} />
            <ServicesH2>Hire a CA</ServicesH2>
            <ServicesP>
              Get connected with number of trusted CA's from our Network
            </ServicesP>
          </ServicesCard>
        </motion.div>
        <motion.div 
        variants={{hidden: {opacity: 0, x:-100},visible: {opacity: 1, x:0}}}
        initial = "hidden"
        animate = {mainControls}
        transition={{duration:0.5, delay:0.25}}
        >
          <ServicesCard>
            <ServicesIcon src={Icon3} />
            <ServicesH2>Get Insights</ServicesH2>
            <ServicesP>
            With each piece of information, you unravel the intricacies of your finances, gaining a clearer view of your tax obligations, deductions, and potential savings
            </ServicesP>
        </ServicesCard>
        </motion.div>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
