import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const FinancialGoals = () => {
  const { financialGoals, setFinancialGoals } = useFinancialPlan();

  const handleInputChange = (e) => {
    setFinancialGoals((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Financial Goals</h3>
      <div className={styles.row}>
        <div>
          <label>Short-Term Goals</label>
          <input
            type="text"
            name="shortTermGoals"
            value={financialGoals.shortTermGoals}
            placeholder="Enter short-term goals (e.g., saving for a vacation)"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Medium-Term Goals</label>
          <input
            type="text"
            name="mediumTermGoals"
            value={financialGoals.mediumTermGoals}
            placeholder="Enter medium-term goals (e.g., buying a home)"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Long-Term Goals</label>
          <input
            type="text"
            name="longTermGoals"
            value={financialGoals.longTermGoals}
            placeholder="Enter long-term goals (e.g., retirement plans)"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialGoals;
