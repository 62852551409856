import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const Uploads = () => {
  const { uploads, setUploads } = useFinancialPlan();

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setUploads((prevUploads) => ({
      ...prevUploads,
      [name]: files[0], // Store the first selected file
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Uploads</h3>
      <div className={styles.row}>
        <div>
          <label>Upload Tax Document</label>
          <input
            type="file"
            name="taxDocument"
            accept=".pdf,.doc,.docx,.jpg,.png"
            onChange={handleFileChange}
          />
        </div>
        <div>
          <label>Upload Related Statements</label>
          <input
            type="file"
            name="relatedStatements"
            accept=".pdf,.doc,.docx,.jpg,.png"
            onChange={handleFileChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Uploads;
