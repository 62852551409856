import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const EstatePlanning = () => {
  const { estatePlanning, setEstatePlanning } = useFinancialPlan();

  const handleInputChange = (e) => {
    setEstatePlanning((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Estate Planning</h3>
      <div className={styles.row}>
        <div>
          <label>Wills</label>
          <input
            type="text"
            name="wills"
            value={estatePlanning.wills}
            placeholder="Enter details about wills"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Trusts</label>
          <input
            type="text"
            name="trusts"
            value={estatePlanning.trusts}
            placeholder="Enter details about trusts"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Powers of Attorney</label>
          <input
            type="text"
            name="powersOfAttorney"
            value={estatePlanning.powersOfAttorney}
            placeholder="Enter details about powers of attorney"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Beneficiary Designations</label>
          <input
            type="text"
            name="beneficiaryDesignations"
            value={estatePlanning.beneficiaryDesignations}
            placeholder="Enter details about beneficiary designations"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default EstatePlanning;
