import React, { createContext, useContext, useState } from "react";

const FinancialPlanContext = createContext();

export const FinancialPlanProvider = ({ children }) => {
  const [personalInformation, setPersonalInformation] = useState({
    fullName: "",
    dateOfBirth: "",
    maritalStatus: "",
    dependents: "",
    occupation: "",
    employer: "",
  });

  const [incomeDetails, setIncomeDetails] = useState({
    annualIncome: "",
    monthlyExpenses: "",
    totalSavings: "",
    totalDebts: "",
    salary: "",
    bonuses: "",
    additionalIncomeSources: "",
    employmentStatus: "",
    currency: ""
  });

  const [expenseOverview, setExpenseOverview] = useState({
    monthlyLivingExpenses: "",
    discretionarySpending: "",
    annualIrregularExpenses: "",
    mortgageLoans: "",
  });

  const [assets, setAssets] = useState({
    bankBalances: "",
    investmentAccounts: "",
    realEstateHoldings: "",
    retirementAccounts: "",
  });

  const [liabilities, setLiabilities] = useState({
    outstandingDebts: "",
    monthlyDebtPayments: "",
  });

  const [financialGoals, setFinancialGoals] = useState({
    shortTermGoals: "",
    mediumTermGoals: "",
    longTermGoals: "",
  });

  const [riskTolerance, setRiskTolerance] = useState({
    riskLevel: 3,
    investmentStrategy: "",
  });

  const [taxSituation, setTaxSituation] = useState({
    currentTaxBracket: "",
    deductionsCredits: "",
  });

  const [insuranceCoverage, setInsuranceCoverage] = useState({
    lifeInsurance: "",
    healthInsurance: "",
    autoInsurance: "",
    homeInsurance: "",
    disabilityInsurance: "",
  });

  const [estatePlanning, setEstatePlanning] = useState({
    wills: "",
    trusts: "",
    powersOfAttorney: "",
    beneficiaryDesignations: "",
  });

  const [retirementPlans, setRetirementPlans] = useState({
    expectedRetirementAge: "",
    lifestyleGoals: "",
    currentSavingsRate: "",
    employerContributions: "",
  });

  const [otherConsiderations, setOtherConsiderations] = useState({
    significantLifeChanges: "",
    healthIssues: "",
    reviewFrequency: "Annual",
  });

  const [majorExpense, setMajorExpense] = useState("");

  const [uploads, setUploads] = useState({
    taxDocument: null,
    relatedStatements: null,
  });
  return (
    <FinancialPlanContext.Provider
      value={{
        personalInformation,
        setPersonalInformation,
        incomeDetails,
        setIncomeDetails,
        expenseOverview,
        setExpenseOverview,
        assets,
        setAssets,
        liabilities,
        setLiabilities,
        financialGoals,
        setFinancialGoals,
        riskTolerance,
        setRiskTolerance,
        taxSituation,
        setTaxSituation,
        insuranceCoverage,
        setInsuranceCoverage,
        estatePlanning,
        setEstatePlanning,
        retirementPlans,
        setRetirementPlans,
        otherConsiderations,
        setOtherConsiderations,
        majorExpense,
        setMajorExpense,
        uploads,
        setUploads,
      }}
    >
      {children}
    </FinancialPlanContext.Provider>
  );
};

export const useFinancialPlan = () => {
  return useContext(FinancialPlanContext);
};
