import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const PersonalInformation = () => {
  const { personalInformation, setPersonalInformation } = useFinancialPlan();

  const handleInputChange = (e) => {
    setPersonalInformation((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div className={styles.section}>
      <h3>Personal Information</h3>
      <div className={styles.row}>
        <div>
          <label>Full Name</label>
          <input
            type="text"
            name="fullName"
            value={personalInformation.fullName}
            placeholder="Enter full name"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Date of Birth</label>
          <input
            type="date"
            name="dateOfBirth"
            value={personalInformation.dateOfBirth}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Marital Status</label>
          <input
            type="text"
            name="maritalStatus"
            value={personalInformation.maritalStatus}
            placeholder="Enter marital status"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Dependents</label>
          <input
            type="number"
            name="dependents"
            value={personalInformation.dependents}
            placeholder="Enter number of dependents"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Occupation</label>
          <input
            type="text"
            name="occupation"
            value={personalInformation.occupation}
            placeholder="Enter occupation"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Current Employer/Business</label>
          <input
            type="text"
            name="employer"
            value={personalInformation.employer}
            placeholder="Enter employer/business"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
