import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const RiskTolerance = () => {
  const { riskTolerance, setRiskTolerance } = useFinancialPlan();

  const handleInputChange = (e) => {
    setRiskTolerance((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRiskLevelChange = (e) => {
    setRiskTolerance((prevState) => ({
      ...prevState,
      riskLevel: parseInt(e.target.value),
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Risk Tolerance</h3>
      <div className={styles.row}>
        <div>
          <label>Risk Level (1-5)</label>
          <input
            type="range"
            name="riskLevel"
            min="1"
            max="5"
            value={riskTolerance.riskLevel}
            onChange={handleRiskLevelChange}
          />
          <span>{riskTolerance.riskLevel}</span>
        </div>
        <div>
          <label>Investment Strategy</label>
          <select
            name="investmentStrategy"
            value={riskTolerance.investmentStrategy}
            onChange={handleInputChange}
          >
            <option value="">Select strategy</option>
            <option value="conservative">Conservative</option>
            <option value="moderate">Moderate</option>
            <option value="aggressive">Aggressive</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default RiskTolerance;
