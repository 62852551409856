import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const InsuranceCoverage = () => {
  const { insuranceCoverage, setInsuranceCoverage } = useFinancialPlan();

  const handleInputChange = (e) => {
    setInsuranceCoverage((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Insurance Coverage</h3>
      <div className={styles.row}>
        <div>
          <label>Life Insurance</label>
          <input
            type="text"
            name="lifeInsurance"
            value={insuranceCoverage.lifeInsurance}
            placeholder="Enter life insurance details"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Health Insurance</label>
          <input
            type="text"
            name="healthInsurance"
            value={insuranceCoverage.healthInsurance}
            placeholder="Enter health insurance details"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Auto Insurance</label>
          <input
            type="text"
            name="autoInsurance"
            value={insuranceCoverage.autoInsurance}
            placeholder="Enter auto insurance details"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Home Insurance</label>
          <input
            type="text"
            name="homeInsurance"
            value={insuranceCoverage.homeInsurance}
            placeholder="Enter home insurance details"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Disability Insurance</label>
          <input
            type="text"
            name="disabilityInsurance"
            value={insuranceCoverage.disabilityInsurance}
            placeholder="Enter disability insurance details"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default InsuranceCoverage;
