import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const MajorExpense = () => {
  const { majorExpense, setMajorExpense } = useFinancialPlan();

  const handleInputChange = (e) => {
    setMajorExpense(e.target.value);
  };

  return (
    <div className={styles.section}>
      <h3>Major Expense</h3>
      <label>
        Do you have any upcoming major expenses in the next 5 years (e.g.,
        Study, Marriage, House purchase)?
      </label>
      <input
        type="text"
        name="majorExpense"
        value={majorExpense}
        placeholder="Enter details about major expenses"
        onChange={handleInputChange}
      />
    </div>
  );
};

export default MajorExpense;
