import React, {useEffect, useRef} from "react";
import {FooterContainer,FooterH2,FooterLink,FooterWrapper} from "./FooterElements";
import "./../../App.css";
import { motion, useInView,useAnimation } from "framer-motion"

const Footer = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true});
  const mainControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView])
  return (
    <FooterContainer>
      <FooterWrapper className="container py-5">
        <motion.div className="row px-4 gy-4"
        ref={ref}
        variants={{hidden: {opacity: 0, x:-100},visible: {opacity: 1, x:0}}}
        initial = "hidden"
        animate = {mainControls}
        transition={{duration:0.5, delay:0.25}}
        >
          <div className="col-lg-3 col-sm-6">
            <FooterH2>About Us</FooterH2>
            <FooterLink href="#">How it works</FooterLink>
            <FooterLink href="#">Testimonials</FooterLink>
            <FooterLink href="#">Careers</FooterLink>
            <FooterLink href="#">Investors</FooterLink>
            <FooterLink href="#">Terms of Service</FooterLink>
          </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>Contact Us</FooterH2>
            <FooterLink href="#">Contact</FooterLink>
            <FooterLink href="#">Support</FooterLink>
            <FooterLink href="#">Destinations</FooterLink>
            <FooterLink href="#">Our Locations</FooterLink>
          </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>Customers</FooterH2>
            <FooterLink href="#">Our Customers</FooterLink>
            <FooterLink href="#">How to reach us</FooterLink>
            <FooterLink href="#">Video Samples</FooterLink>
            <FooterLink href="#">Agency</FooterLink>
          </div>
          <div className="col-lg-3 col-sm-6">
            <FooterH2>Follow Us</FooterH2>
            <FooterLink href="#">Instagram</FooterLink>
            <FooterLink href="#">Youtube</FooterLink>
            <FooterLink href="#">Facebook</FooterLink>
            <FooterLink href="#">LinkedIn</FooterLink>
          </div>
        </motion.div>
        <div className="row">
          <div className="col-12 mt-5 ">
            <h1 className="logo-text gray-text center-align">NriTax</h1>
          </div>
        </div>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
