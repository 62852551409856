import React from "react";
import { useFinancialPlan } from "../FinancialPlanContext";
import styles from "./Form.module.css";

const Assets = () => {
  const { assets, setAssets } = useFinancialPlan();

  const handleInputChange = (e) => {
    setAssets((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.section}>
      <h3>Assets</h3>
      <div className={styles.row}>
        <div>
          <label>Bank Account Balances</label>
          <input
            type="text"
            name="bankBalances"
            value={assets.bankBalances}
            placeholder="Enter total bank balances"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Investment Accounts</label>
          <input
            type="text"
            name="investmentAccounts"
            value={assets.investmentAccounts}
            placeholder="Enter total in investment accounts (stocks, bonds, mutual funds)"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <label>Real Estate Holdings</label>
          <input
            type="text"
            name="realEstateHoldings"
            value={assets.realEstateHoldings}
            placeholder="Enter real estate holdings and market value"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Retirement Accounts</label>
          <input
            type="text"
            name="retirementAccounts"
            value={assets.retirementAccounts}
            placeholder="Enter retirement accounts (401(k), IRA, pension plans)"
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Assets;
